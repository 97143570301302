import './src/assets/stylesheets/vendor/index.scss';
import './src/assets/stylesheets/main.scss';

export const disableCorePrefetching = () => true;

let hasUserScrolled = false;

const handleUserScroll = () => {
  hasUserScrolled = true;
};

export const onClientEntry = () => {
  window.addEventListener('scroll', handleUserScroll);
};

export const onRouteUpdate = ({ location, prevLocation }) => {
  if (location.href.includes('#')) return;

  // Only scroll to the top on refresh if the user hasn't scrolled
  if (!prevLocation && !hasUserScrolled) {
    window.scrollTo(0, 0);
  }

  window.history.scrollRestoration = 'manual';
};

// Clean up event listener when the user leaves the page
export const onRouteUpdateDelayed = () => {
  window.removeEventListener('scroll', handleUserScroll);
  hasUserScrolled = false;
};
