exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-js": () => import("./../../../src/pages/about-us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-blog-all-page-js": () => import("./../../../src/pages/blog/all.page.js" /* webpackChunkName: "component---src-pages-blog-all-page-js" */),
  "component---src-pages-blog-customer-stories-page-js": () => import("./../../../src/pages/blog/customer-stories.page.js" /* webpackChunkName: "component---src-pages-blog-customer-stories-page-js" */),
  "component---src-pages-blog-education-page-js": () => import("./../../../src/pages/blog/education.page.js" /* webpackChunkName: "component---src-pages-blog-education-page-js" */),
  "component---src-pages-blog-filmmaking-page-js": () => import("./../../../src/pages/blog/filmmaking.page.js" /* webpackChunkName: "component---src-pages-blog-filmmaking-page-js" */),
  "component---src-pages-blog-index-js": () => import("./../../../src/pages/blog/index.js" /* webpackChunkName: "component---src-pages-blog-index-js" */),
  "component---src-pages-blog-legal-page-js": () => import("./../../../src/pages/blog/legal.page.js" /* webpackChunkName: "component---src-pages-blog-legal-page-js" */),
  "component---src-pages-blog-redaction-page-js": () => import("./../../../src/pages/blog/redaction.page.js" /* webpackChunkName: "component---src-pages-blog-redaction-page-js" */),
  "component---src-pages-blog-reduct-page-js": () => import("./../../../src/pages/blog/reduct.page.js" /* webpackChunkName: "component---src-pages-blog-reduct-page-js" */),
  "component---src-pages-blog-research-page-js": () => import("./../../../src/pages/blog/research.page.js" /* webpackChunkName: "component---src-pages-blog-research-page-js" */),
  "component---src-pages-blog-transcription-page-js": () => import("./../../../src/pages/blog/transcription.page.js" /* webpackChunkName: "component---src-pages-blog-transcription-page-js" */),
  "component---src-pages-blog-translation-page-js": () => import("./../../../src/pages/blog/translation.page.js" /* webpackChunkName: "component---src-pages-blog-translation-page-js" */),
  "component---src-pages-blog-video-page-js": () => import("./../../../src/pages/blog/video.page.js" /* webpackChunkName: "component---src-pages-blog-video-page-js" */),
  "component---src-pages-case-studies-angles-law-firm-js": () => import("./../../../src/pages/case-studies/angles-law-firm.js" /* webpackChunkName: "component---src-pages-case-studies-angles-law-firm-js" */),
  "component---src-pages-case-studies-colorado-public-defenders-js": () => import("./../../../src/pages/case-studies/colorado-public-defenders.js" /* webpackChunkName: "component---src-pages-case-studies-colorado-public-defenders-js" */),
  "component---src-pages-case-studies-index-js": () => import("./../../../src/pages/case-studies/index.js" /* webpackChunkName: "component---src-pages-case-studies-index-js" */),
  "component---src-pages-contact-sales-js": () => import("./../../../src/pages/contact-sales.js" /* webpackChunkName: "component---src-pages-contact-sales-js" */),
  "component---src-pages-customer-success-js": () => import("./../../../src/pages/customer-success.js" /* webpackChunkName: "component---src-pages-customer-success-js" */),
  "component---src-pages-education-js": () => import("./../../../src/pages/education.js" /* webpackChunkName: "component---src-pages-education-js" */),
  "component---src-pages-embed-js": () => import("./../../../src/pages/embed.js" /* webpackChunkName: "component---src-pages-embed-js" */),
  "component---src-pages-enterprise-js": () => import("./../../../src/pages/enterprise.js" /* webpackChunkName: "component---src-pages-enterprise-js" */),
  "component---src-pages-filmmaking-js": () => import("./../../../src/pages/filmmaking.js" /* webpackChunkName: "component---src-pages-filmmaking-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-integrations-index-js": () => import("./../../../src/pages/integrations/index.js" /* webpackChunkName: "component---src-pages-integrations-index-js" */),
  "component---src-pages-legal-js": () => import("./../../../src/pages/legal.js" /* webpackChunkName: "component---src-pages-legal-js" */),
  "component---src-pages-live-capture-js": () => import("./../../../src/pages/live-capture.js" /* webpackChunkName: "component---src-pages-live-capture-js" */),
  "component---src-pages-made-with-reduct-js": () => import("./../../../src/pages/made-with-reduct.js" /* webpackChunkName: "component---src-pages-made-with-reduct-js" */),
  "component---src-pages-marketing-js": () => import("./../../../src/pages/marketing.js" /* webpackChunkName: "component---src-pages-marketing-js" */),
  "component---src-pages-newsroom-js": () => import("./../../../src/pages/newsroom.js" /* webpackChunkName: "component---src-pages-newsroom-js" */),
  "component---src-pages-pricing-js": () => import("./../../../src/pages/pricing.js" /* webpackChunkName: "component---src-pages-pricing-js" */),
  "component---src-pages-product-ask-a-recording-js": () => import("./../../../src/pages/product/ask-a-recording.js" /* webpackChunkName: "component---src-pages-product-ask-a-recording-js" */),
  "component---src-pages-product-edit-video-js": () => import("./../../../src/pages/product/edit-video.js" /* webpackChunkName: "component---src-pages-product-edit-video-js" */),
  "component---src-pages-product-index-js": () => import("./../../../src/pages/product/index.js" /* webpackChunkName: "component---src-pages-product-index-js" */),
  "component---src-pages-product-multicam-js": () => import("./../../../src/pages/product/multicam.js" /* webpackChunkName: "component---src-pages-product-multicam-js" */),
  "component---src-pages-product-powerful-search-js": () => import("./../../../src/pages/product/powerful-search.js" /* webpackChunkName: "component---src-pages-product-powerful-search-js" */),
  "component---src-pages-product-redaction-js": () => import("./../../../src/pages/product/redaction.js" /* webpackChunkName: "component---src-pages-product-redaction-js" */),
  "component---src-pages-product-summarizer-js": () => import("./../../../src/pages/product/summarizer.js" /* webpackChunkName: "component---src-pages-product-summarizer-js" */),
  "component---src-pages-product-translation-german-to-english-js": () => import("./../../../src/pages/product/translation/german-to-english.js" /* webpackChunkName: "component---src-pages-product-translation-german-to-english-js" */),
  "component---src-pages-product-translation-italian-to-english-js": () => import("./../../../src/pages/product/translation/italian-to-english.js" /* webpackChunkName: "component---src-pages-product-translation-italian-to-english-js" */),
  "component---src-pages-product-translation-js": () => import("./../../../src/pages/product/translation.js" /* webpackChunkName: "component---src-pages-product-translation-js" */),
  "component---src-pages-product-translation-portuguese-to-english-js": () => import("./../../../src/pages/product/translation/portuguese-to-english.js" /* webpackChunkName: "component---src-pages-product-translation-portuguese-to-english-js" */),
  "component---src-pages-product-translation-spanish-to-english-js": () => import("./../../../src/pages/product/translation/spanish-to-english.js" /* webpackChunkName: "component---src-pages-product-translation-spanish-to-english-js" */),
  "component---src-pages-product-video-storage-js": () => import("./../../../src/pages/product/video-storage.js" /* webpackChunkName: "component---src-pages-product-video-storage-js" */),
  "component---src-pages-product-wp-plugin-js": () => import("./../../../src/pages/product/wp-plugin.js" /* webpackChunkName: "component---src-pages-product-wp-plugin-js" */),
  "component---src-pages-request-demo-js": () => import("./../../../src/pages/request-demo.js" /* webpackChunkName: "component---src-pages-request-demo-js" */),
  "component---src-pages-research-js": () => import("./../../../src/pages/research.js" /* webpackChunkName: "component---src-pages-research-js" */),
  "component---src-pages-security-jsx": () => import("./../../../src/pages/security.jsx" /* webpackChunkName: "component---src-pages-security-jsx" */),
  "component---src-pages-signup-js": () => import("./../../../src/pages/signup.js" /* webpackChunkName: "component---src-pages-signup-js" */),
  "component---src-pages-tools-audio-joiner-js": () => import("./../../../src/pages/tools/audio-joiner.js" /* webpackChunkName: "component---src-pages-tools-audio-joiner-js" */),
  "component---src-pages-tools-audio-trimmer-js": () => import("./../../../src/pages/tools/audio-trimmer.js" /* webpackChunkName: "component---src-pages-tools-audio-trimmer-js" */),
  "component---src-pages-tools-index-js": () => import("./../../../src/pages/tools/index.js" /* webpackChunkName: "component---src-pages-tools-index-js" */),
  "component---src-pages-tools-online-audio-cutter-js": () => import("./../../../src/pages/tools/online-audio-cutter.js" /* webpackChunkName: "component---src-pages-tools-online-audio-cutter-js" */),
  "component---src-pages-tools-online-video-cutter-js": () => import("./../../../src/pages/tools/online-video-cutter.js" /* webpackChunkName: "component---src-pages-tools-online-video-cutter-js" */),
  "component---src-pages-tools-srt-editor-js": () => import("./../../../src/pages/tools/srt-editor.js" /* webpackChunkName: "component---src-pages-tools-srt-editor-js" */),
  "component---src-pages-tools-subtitle-editor-js": () => import("./../../../src/pages/tools/subtitle-editor.js" /* webpackChunkName: "component---src-pages-tools-subtitle-editor-js" */),
  "component---src-pages-tools-video-merger-js": () => import("./../../../src/pages/tools/video-merger.js" /* webpackChunkName: "component---src-pages-tools-video-merger-js" */),
  "component---src-pages-tools-video-trimmer-js": () => import("./../../../src/pages/tools/video-trimmer.js" /* webpackChunkName: "component---src-pages-tools-video-trimmer-js" */),
  "component---src-pages-transcribe-auto-subtitle-generator-js": () => import("./../../../src/pages/transcribe/auto-subtitle-generator.js" /* webpackChunkName: "component---src-pages-transcribe-auto-subtitle-generator-js" */),
  "component---src-pages-transcribe-benchmark-js": () => import("./../../../src/pages/transcribe/benchmark.js" /* webpackChunkName: "component---src-pages-transcribe-benchmark-js" */),
  "component---src-pages-transcribe-chinese-js": () => import("./../../../src/pages/transcribe/chinese.js" /* webpackChunkName: "component---src-pages-transcribe-chinese-js" */),
  "component---src-pages-transcribe-guidelines-index-js": () => import("./../../../src/pages/transcribe/guidelines/index.js" /* webpackChunkName: "component---src-pages-transcribe-guidelines-index-js" */),
  "component---src-pages-transcribe-human-js": () => import("./../../../src/pages/transcribe/human.js" /* webpackChunkName: "component---src-pages-transcribe-human-js" */),
  "component---src-pages-transcribe-index-js": () => import("./../../../src/pages/transcribe/index.js" /* webpackChunkName: "component---src-pages-transcribe-index-js" */),
  "component---src-pages-transcribe-interview-js": () => import("./../../../src/pages/transcribe/interview.js" /* webpackChunkName: "component---src-pages-transcribe-interview-js" */),
  "component---src-pages-transcribe-japanese-js": () => import("./../../../src/pages/transcribe/japanese.js" /* webpackChunkName: "component---src-pages-transcribe-japanese-js" */),
  "component---src-pages-transcribe-lecture-videos-js": () => import("./../../../src/pages/transcribe/lecture-videos.js" /* webpackChunkName: "component---src-pages-transcribe-lecture-videos-js" */),
  "component---src-pages-transcribe-legal-transcription-generator-js": () => import("./../../../src/pages/transcribe/legal-transcription-generator.js" /* webpackChunkName: "component---src-pages-transcribe-legal-transcription-generator-js" */),
  "component---src-pages-transcribe-legal-transcription-js": () => import("./../../../src/pages/transcribe/legal-transcription.js" /* webpackChunkName: "component---src-pages-transcribe-legal-transcription-js" */),
  "component---src-pages-transcribe-medical-js": () => import("./../../../src/pages/transcribe/medical.js" /* webpackChunkName: "component---src-pages-transcribe-medical-js" */),
  "component---src-pages-transcribe-meetings-js": () => import("./../../../src/pages/transcribe/meetings.js" /* webpackChunkName: "component---src-pages-transcribe-meetings-js" */),
  "component---src-pages-transcribe-norwegian-js": () => import("./../../../src/pages/transcribe/norwegian.js" /* webpackChunkName: "component---src-pages-transcribe-norwegian-js" */),
  "component---src-pages-transcribe-podcast-js": () => import("./../../../src/pages/transcribe/podcast.js" /* webpackChunkName: "component---src-pages-transcribe-podcast-js" */),
  "component---src-pages-transcribe-portuguese-js": () => import("./../../../src/pages/transcribe/portuguese.js" /* webpackChunkName: "component---src-pages-transcribe-portuguese-js" */),
  "component---src-pages-transcribe-public-defense-js": () => import("./../../../src/pages/transcribe/public-defense.js" /* webpackChunkName: "component---src-pages-transcribe-public-defense-js" */),
  "component---src-pages-transcribe-spanish-video-to-text-js": () => import("./../../../src/pages/transcribe/spanish-video-to-text.js" /* webpackChunkName: "component---src-pages-transcribe-spanish-video-to-text-js" */),
  "component---src-pages-transcribe-transcription-practice-diff-js": () => import("./../../../src/pages/transcribe/transcription-practice/diff.js" /* webpackChunkName: "component---src-pages-transcribe-transcription-practice-diff-js" */),
  "component---src-pages-transcribe-transcription-practice-easy-editor-js": () => import("./../../../src/pages/transcribe/transcription-practice/easy/editor.js" /* webpackChunkName: "component---src-pages-transcribe-transcription-practice-easy-editor-js" */),
  "component---src-pages-transcribe-transcription-practice-hard-editor-js": () => import("./../../../src/pages/transcribe/transcription-practice/hard/editor.js" /* webpackChunkName: "component---src-pages-transcribe-transcription-practice-hard-editor-js" */),
  "component---src-pages-transcribe-transcription-practice-index-js": () => import("./../../../src/pages/transcribe/transcription-practice/index.js" /* webpackChunkName: "component---src-pages-transcribe-transcription-practice-index-js" */),
  "component---src-pages-transcribe-transcription-practice-medium-editor-js": () => import("./../../../src/pages/transcribe/transcription-practice/medium/editor.js" /* webpackChunkName: "component---src-pages-transcribe-transcription-practice-medium-editor-js" */),
  "component---src-pages-transcribe-ux-research-js": () => import("./../../../src/pages/transcribe/ux-research.js" /* webpackChunkName: "component---src-pages-transcribe-ux-research-js" */),
  "component---src-pages-transcribe-youtube-to-text-js": () => import("./../../../src/pages/transcribe/youtube-to-text.js" /* webpackChunkName: "component---src-pages-transcribe-youtube-to-text-js" */),
  "component---src-pages-transcribe-zoom-recording-js": () => import("./../../../src/pages/transcribe/zoom-recording.js" /* webpackChunkName: "component---src-pages-transcribe-zoom-recording-js" */),
  "component---src-pages-vs-condens-js": () => import("./../../../src/pages/vs/condens.js" /* webpackChunkName: "component---src-pages-vs-condens-js" */),
  "component---src-pages-vs-dovetail-js": () => import("./../../../src/pages/vs/dovetail.js" /* webpackChunkName: "component---src-pages-vs-dovetail-js" */),
  "component---src-pages-vs-enjoyhq-js": () => import("./../../../src/pages/vs/enjoyhq.js" /* webpackChunkName: "component---src-pages-vs-enjoyhq-js" */),
  "component---src-pages-vs-justicetext-js": () => import("./../../../src/pages/vs/justicetext.js" /* webpackChunkName: "component---src-pages-vs-justicetext-js" */),
  "component---src-pages-vs-rev-js": () => import("./../../../src/pages/vs/rev.js" /* webpackChunkName: "component---src-pages-vs-rev-js" */),
  "component---src-templates-blog-post-js-content-file-path-src-blog-4-reasons-why-you-should-outsource-your-legal-transcription-needs-mdx": () => import("./../../../src/templates/blog/post.js?__contentFilePath=/app/reduct-dot-video/src/blog/4-reasons-why-you-should-outsource-your-legal-transcription-needs.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-src-blog-4-reasons-why-you-should-outsource-your-legal-transcription-needs-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-src-blog-5-best-legal-transcription-mdx": () => import("./../../../src/templates/blog/post.js?__contentFilePath=/app/reduct-dot-video/src/blog/5-best-legal-transcription.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-src-blog-5-best-legal-transcription-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-src-blog-6-tips-for-making-virtual-meetings-more-accessible-mdx": () => import("./../../../src/templates/blog/post.js?__contentFilePath=/app/reduct-dot-video/src/blog/6-Tips-for-Making-Virtual-Meetings-More-Accessible.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-src-blog-6-tips-for-making-virtual-meetings-more-accessible-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-src-blog-7-easy-steps-to-create-an-srt-file-mdx": () => import("./../../../src/templates/blog/post.js?__contentFilePath=/app/reduct-dot-video/src/blog/7-easy-steps-to-create-an-SRT-file.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-src-blog-7-easy-steps-to-create-an-srt-file-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-src-blog-a-close-read-of-a-tag-group-mdx": () => import("./../../../src/templates/blog/post.js?__contentFilePath=/app/reduct-dot-video/src/blog/a-close-read-of-a-tag-group.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-src-blog-a-close-read-of-a-tag-group-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-src-blog-add-captions-to-lecture-videos-mdx": () => import("./../../../src/templates/blog/post.js?__contentFilePath=/app/reduct-dot-video/src/blog/add-captions-to-lecture-videos.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-src-blog-add-captions-to-lecture-videos-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-src-blog-add-captions-to-videos-mdx": () => import("./../../../src/templates/blog/post.js?__contentFilePath=/app/reduct-dot-video/src/blog/add-captions-to-videos.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-src-blog-add-captions-to-videos-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-src-blog-adding-captions-and-subtitles-to-youtube-videos-mdx": () => import("./../../../src/templates/blog/post.js?__contentFilePath=/app/reduct-dot-video/src/blog/adding-captions-and-subtitles-to-youtube-videos.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-src-blog-adding-captions-and-subtitles-to-youtube-videos-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-src-blog-ai-legal-transcription-mdx": () => import("./../../../src/templates/blog/post.js?__contentFilePath=/app/reduct-dot-video/src/blog/ai-legal-transcription.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-src-blog-ai-legal-transcription-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-src-blog-ai-llm-reliability-mdx": () => import("./../../../src/templates/blog/post.js?__contentFilePath=/app/reduct-dot-video/src/blog/ai-llm-reliability.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-src-blog-ai-llm-reliability-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-src-blog-ai-summarization-for-youtube-videos-key-benefits-mdx": () => import("./../../../src/templates/blog/post.js?__contentFilePath=/app/reduct-dot-video/src/blog/ai-summarization-for-youtube-videos-key-benefits.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-src-blog-ai-summarization-for-youtube-videos-key-benefits-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-src-blog-airgram-alternatives-mdx": () => import("./../../../src/templates/blog/post.js?__contentFilePath=/app/reduct-dot-video/src/blog/airgram-alternatives.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-src-blog-airgram-alternatives-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-src-blog-audio-redaction-law-enforcement-mdx": () => import("./../../../src/templates/blog/post.js?__contentFilePath=/app/reduct-dot-video/src/blog/audio-redaction-law-enforcement.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-src-blog-audio-redaction-law-enforcement-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-src-blog-backup-zoom-cloud-recordings-mdx": () => import("./../../../src/templates/blog/post.js?__contentFilePath=/app/reduct-dot-video/src/blog/backup-zoom-cloud-recordings.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-src-blog-backup-zoom-cloud-recordings-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-src-blog-benefits-of-ai-video-summarizer-for-researchers-mdx": () => import("./../../../src/templates/blog/post.js?__contentFilePath=/app/reduct-dot-video/src/blog/benefits-of-ai-video-summarizer-for-researchers.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-src-blog-benefits-of-ai-video-summarizer-for-researchers-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-src-blog-best-german-transcription-software-mdx": () => import("./../../../src/templates/blog/post.js?__contentFilePath=/app/reduct-dot-video/src/blog/best-german-transcription-software.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-src-blog-best-german-transcription-software-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-src-blog-best-online-subtitle-translator-mdx": () => import("./../../../src/templates/blog/post.js?__contentFilePath=/app/reduct-dot-video/src/blog/best-online-subtitle-translator.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-src-blog-best-online-subtitle-translator-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-src-blog-best-redaction-softwares-mdx": () => import("./../../../src/templates/blog/post.js?__contentFilePath=/app/reduct-dot-video/src/blog/best-redaction-softwares.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-src-blog-best-redaction-softwares-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-src-blog-best-video-editing-software-for-beginners-mdx": () => import("./../../../src/templates/blog/post.js?__contentFilePath=/app/reduct-dot-video/src/blog/best-video-editing-software-for-beginners.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-src-blog-best-video-editing-software-for-beginners-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-src-blog-blur-faces-in-youtube-videos-mdx": () => import("./../../../src/templates/blog/post.js?__contentFilePath=/app/reduct-dot-video/src/blog/blur-faces-in-youtube-videos.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-src-blog-blur-faces-in-youtube-videos-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-src-blog-blur-faces-online-mdx": () => import("./../../../src/templates/blog/post.js?__contentFilePath=/app/reduct-dot-video/src/blog/blur-faces-online.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-src-blog-blur-faces-online-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-src-blog-blur-pii-in-google-meet-recordings-mdx": () => import("./../../../src/templates/blog/post.js?__contentFilePath=/app/reduct-dot-video/src/blog/blur-pii-in-google-meet-recordings.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-src-blog-blur-pii-in-google-meet-recordings-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-src-blog-blurring-faces-what-and-how-mdx": () => import("./../../../src/templates/blog/post.js?__contentFilePath=/app/reduct-dot-video/src/blog/blurring-faces-what-and-how.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-src-blog-blurring-faces-what-and-how-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-src-blog-captions-on-facebook-videos-mdx": () => import("./../../../src/templates/blog/post.js?__contentFilePath=/app/reduct-dot-video/src/blog/captions-on-facebook-videos.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-src-blog-captions-on-facebook-videos-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-src-blog-censoring-faces-ethical-considerations-mdx": () => import("./../../../src/templates/blog/post.js?__contentFilePath=/app/reduct-dot-video/src/blog/censoring-faces-ethical-considerations.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-src-blog-censoring-faces-ethical-considerations-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-src-blog-cle-seminar-louisiana-mdx": () => import("./../../../src/templates/blog/post.js?__contentFilePath=/app/reduct-dot-video/src/blog/cle-seminar-louisiana.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-src-blog-cle-seminar-louisiana-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-src-blog-closed-caption-best-practices-comprehensive-guide-mdx": () => import("./../../../src/templates/blog/post.js?__contentFilePath=/app/reduct-dot-video/src/blog/closed-caption-best-practices-comprehensive-guide.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-src-blog-closed-caption-best-practices-comprehensive-guide-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-src-blog-closed-vs-open-captions-mdx": () => import("./../../../src/templates/blog/post.js?__contentFilePath=/app/reduct-dot-video/src/blog/closed-vs-open-captions.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-src-blog-closed-vs-open-captions-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-src-blog-cloud-based-video-editing-software-mdx": () => import("./../../../src/templates/blog/post.js?__contentFilePath=/app/reduct-dot-video/src/blog/cloud-based-video-editing-software.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-src-blog-cloud-based-video-editing-software-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-src-blog-cloud-storage-for-video-editing-mdx": () => import("./../../../src/templates/blog/post.js?__contentFilePath=/app/reduct-dot-video/src/blog/cloud-storage-for-video-editing.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-src-blog-cloud-storage-for-video-editing-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-src-blog-collaborative-video-editor-mdx": () => import("./../../../src/templates/blog/post.js?__contentFilePath=/app/reduct-dot-video/src/blog/collaborative-video-editor.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-src-blog-collaborative-video-editor-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-src-blog-combine-video-clips-mdx": () => import("./../../../src/templates/blog/post.js?__contentFilePath=/app/reduct-dot-video/src/blog/combine-video-clips.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-src-blog-combine-video-clips-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-src-blog-computer-transcription-vs-human-transcription-mdx": () => import("./../../../src/templates/blog/post.js?__contentFilePath=/app/reduct-dot-video/src/blog/computer-transcription-vs-human-transcription.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-src-blog-computer-transcription-vs-human-transcription-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-src-blog-condens-alternatives-mdx": () => import("./../../../src/templates/blog/post.js?__contentFilePath=/app/reduct-dot-video/src/blog/condens-alternatives.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-src-blog-condens-alternatives-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-src-blog-cover-faces-in-videos-mdx": () => import("./../../../src/templates/blog/post.js?__contentFilePath=/app/reduct-dot-video/src/blog/cover-faces-in-videos.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-src-blog-cover-faces-in-videos-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-src-blog-create-instagram-thread-mdx": () => import("./../../../src/templates/blog/post.js?__contentFilePath=/app/reduct-dot-video/src/blog/create-instagram-thread.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-src-blog-create-instagram-thread-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-src-blog-create-youtube-chapters-mdx": () => import("./../../../src/templates/blog/post.js?__contentFilePath=/app/reduct-dot-video/src/blog/create-youtube-chapters.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-src-blog-create-youtube-chapters-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-src-blog-criminal-investigation-redaction-mdx": () => import("./../../../src/templates/blog/post.js?__contentFilePath=/app/reduct-dot-video/src/blog/criminal-investigation-redaction.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-src-blog-criminal-investigation-redaction-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-src-blog-davinci-resolve-alternatives-mdx": () => import("./../../../src/templates/blog/post.js?__contentFilePath=/app/reduct-dot-video/src/blog/davinci-resolve-alternatives.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-src-blog-davinci-resolve-alternatives-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-src-blog-debunking-myths-about-democratizing-research-mdx": () => import("./../../../src/templates/blog/post.js?__contentFilePath=/app/reduct-dot-video/src/blog/debunking-myths-about-democratizing-research.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-src-blog-debunking-myths-about-democratizing-research-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-src-blog-democratizing-mdx": () => import("./../../../src/templates/blog/post.js?__contentFilePath=/app/reduct-dot-video/src/blog/democratizing.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-src-blog-democratizing-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-src-blog-descript-alternatives-mdx": () => import("./../../../src/templates/blog/post.js?__contentFilePath=/app/reduct-dot-video/src/blog/descript-alternatives.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-src-blog-descript-alternatives-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-src-blog-digital-evidence-for-criminal-defense-attorneys-mdx": () => import("./../../../src/templates/blog/post.js?__contentFilePath=/app/reduct-dot-video/src/blog/digital-evidence-for-criminal-defense-attorneys.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-src-blog-digital-evidence-for-criminal-defense-attorneys-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-src-blog-documentary-film-meets-design-research-ar-2022-mdx": () => import("./../../../src/templates/blog/post.js?__contentFilePath=/app/reduct-dot-video/src/blog/documentary-film-meets-design-research-AR2022.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-src-blog-documentary-film-meets-design-research-ar-2022-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-src-blog-dovetail-alternatives-mdx": () => import("./../../../src/templates/blog/post.js?__contentFilePath=/app/reduct-dot-video/src/blog/dovetail-alternatives.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-src-blog-dovetail-alternatives-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-src-blog-drive-account-renewals-with-video-mdx": () => import("./../../../src/templates/blog/post.js?__contentFilePath=/app/reduct-dot-video/src/blog/drive-account-renewals-with-video.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-src-blog-drive-account-renewals-with-video-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-src-blog-e-discovery-software-mdx": () => import("./../../../src/templates/blog/post.js?__contentFilePath=/app/reduct-dot-video/src/blog/e-discovery-software.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-src-blog-e-discovery-software-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-src-blog-easily-edit-lecture-recordings-mdx": () => import("./../../../src/templates/blog/post.js?__contentFilePath=/app/reduct-dot-video/src/blog/easily-edit-lecture-recordings.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-src-blog-easily-edit-lecture-recordings-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-src-blog-easy-video-editor-mdx": () => import("./../../../src/templates/blog/post.js?__contentFilePath=/app/reduct-dot-video/src/blog/easy-video-editor.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-src-blog-easy-video-editor-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-src-blog-engaging-lecture-recordings-mdx": () => import("./../../../src/templates/blog/post.js?__contentFilePath=/app/reduct-dot-video/src/blog/engaging-lecture-recordings.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-src-blog-engaging-lecture-recordings-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-src-blog-english-subtitle-spanish-videos-mdx": () => import("./../../../src/templates/blog/post.js?__contentFilePath=/app/reduct-dot-video/src/blog/english-subtitle-spanish-videos.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-src-blog-english-subtitle-spanish-videos-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-src-blog-essential-tools-for-teachers-new-to-remote-education-mdx": () => import("./../../../src/templates/blog/post.js?__contentFilePath=/app/reduct-dot-video/src/blog/essential-tools-for-teachers-new-to-remote-education.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-src-blog-essential-tools-for-teachers-new-to-remote-education-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-src-blog-feedback-on-video-mdx": () => import("./../../../src/templates/blog/post.js?__contentFilePath=/app/reduct-dot-video/src/blog/feedback-on-video.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-src-blog-feedback-on-video-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-src-blog-filmora-alternatives-mdx": () => import("./../../../src/templates/blog/post.js?__contentFilePath=/app/reduct-dot-video/src/blog/filmora-alternatives.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-src-blog-filmora-alternatives-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-src-blog-final-cut-pro-alternatives-mdx": () => import("./../../../src/templates/blog/post.js?__contentFilePath=/app/reduct-dot-video/src/blog/final-cut-pro-alternatives.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-src-blog-final-cut-pro-alternatives-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-src-blog-fireflies-alternatives-mdx": () => import("./../../../src/templates/blog/post.js?__contentFilePath=/app/reduct-dot-video/src/blog/fireflies-alternatives.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-src-blog-fireflies-alternatives-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-src-blog-first-event-strategic-impact-mdx": () => import("./../../../src/templates/blog/post.js?__contentFilePath=/app/reduct-dot-video/src/blog/first-event-strategic-impact.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-src-blog-first-event-strategic-impact-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-src-blog-foia-redaction-software-mdx": () => import("./../../../src/templates/blog/post.js?__contentFilePath=/app/reduct-dot-video/src/blog/foia-redaction-software.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-src-blog-foia-redaction-software-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-src-blog-french-transcription-software-mdx": () => import("./../../../src/templates/blog/post.js?__contentFilePath=/app/reduct-dot-video/src/blog/french-transcription-software.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-src-blog-french-transcription-software-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-src-blog-grain-alternatives-mdx": () => import("./../../../src/templates/blog/post.js?__contentFilePath=/app/reduct-dot-video/src/blog/grain-alternatives.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-src-blog-grain-alternatives-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-src-blog-how-can-educational-institutions-benefit-from-reduct-mdx": () => import("./../../../src/templates/blog/post.js?__contentFilePath=/app/reduct-dot-video/src/blog/how-can-educational-institutions-benefit-from-reduct.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-src-blog-how-can-educational-institutions-benefit-from-reduct-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-src-blog-how-public-defenders-use-video-evidence-mdx": () => import("./../../../src/templates/blog/post.js?__contentFilePath=/app/reduct-dot-video/src/blog/how-public-defenders-use-video-evidence.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-src-blog-how-public-defenders-use-video-evidence-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-src-blog-how-to-add-captions-and-subtitles-in-linkedin-videos-mdx": () => import("./../../../src/templates/blog/post.js?__contentFilePath=/app/reduct-dot-video/src/blog/how-to-add-captions-and-subtitles-in-linkedin-videos.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-src-blog-how-to-add-captions-and-subtitles-in-linkedin-videos-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-src-blog-how-to-create-video-snippets-mdx": () => import("./../../../src/templates/blog/post.js?__contentFilePath=/app/reduct-dot-video/src/blog/how-to-create-video-snippets.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-src-blog-how-to-create-video-snippets-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-src-blog-how-to-edit-a-webinar-mdx": () => import("./../../../src/templates/blog/post.js?__contentFilePath=/app/reduct-dot-video/src/blog/how-to-edit-a-webinar.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-src-blog-how-to-edit-a-webinar-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-src-blog-how-to-edit-an-interview-video-mdx": () => import("./../../../src/templates/blog/post.js?__contentFilePath=/app/reduct-dot-video/src/blog/how-to-edit-an-interview-video.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-src-blog-how-to-edit-an-interview-video-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-src-blog-how-to-edit-subtitles-online-in-5-minutes-mdx": () => import("./../../../src/templates/blog/post.js?__contentFilePath=/app/reduct-dot-video/src/blog/how-to-edit-subtitles-online-in-5-minutes.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-src-blog-how-to-edit-subtitles-online-in-5-minutes-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-src-blog-how-to-edit-video-fast-mdx": () => import("./../../../src/templates/blog/post.js?__contentFilePath=/app/reduct-dot-video/src/blog/how-to-edit-video-fast.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-src-blog-how-to-edit-video-fast-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-src-blog-how-to-edit-zoom-recordings-mdx": () => import("./../../../src/templates/blog/post.js?__contentFilePath=/app/reduct-dot-video/src/blog/how-to-edit-zoom-recordings.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-src-blog-how-to-edit-zoom-recordings-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-src-blog-how-to-live-transcribe-google-meet-calls-mdx": () => import("./../../../src/templates/blog/post.js?__contentFilePath=/app/reduct-dot-video/src/blog/how-to-live-transcribe-google-meet-calls.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-src-blog-how-to-live-transcribe-google-meet-calls-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-src-blog-how-to-live-transcribe-microsoft-teams-calls-mdx": () => import("./../../../src/templates/blog/post.js?__contentFilePath=/app/reduct-dot-video/src/blog/how-to-live-transcribe-microsoft-teams-calls.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-src-blog-how-to-live-transcribe-microsoft-teams-calls-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-src-blog-how-to-redact-audio-mdx": () => import("./../../../src/templates/blog/post.js?__contentFilePath=/app/reduct-dot-video/src/blog/how-to-redact-audio.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-src-blog-how-to-redact-audio-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-src-blog-how-to-redact-zoom-recordings-mdx": () => import("./../../../src/templates/blog/post.js?__contentFilePath=/app/reduct-dot-video/src/blog/how-to-redact-zoom-recordings.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-src-blog-how-to-redact-zoom-recordings-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-src-blog-how-to-share-research-findings-mdx": () => import("./../../../src/templates/blog/post.js?__contentFilePath=/app/reduct-dot-video/src/blog/how-to-share-research-findings.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-src-blog-how-to-share-research-findings-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-src-blog-how-to-summarize-a-zoom-recording-mdx": () => import("./../../../src/templates/blog/post.js?__contentFilePath=/app/reduct-dot-video/src/blog/how-to-summarize-a-zoom-recording.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-src-blog-how-to-summarize-a-zoom-recording-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-src-blog-how-to-transcribe-interviews-in-qualitative-research-mdx": () => import("./../../../src/templates/blog/post.js?__contentFilePath=/app/reduct-dot-video/src/blog/how-to-transcribe-interviews-in-qualitative-research.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-src-blog-how-to-transcribe-interviews-in-qualitative-research-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-src-blog-how-to-transcribe-your-audio-to-text-mdx": () => import("./../../../src/templates/blog/post.js?__contentFilePath=/app/reduct-dot-video/src/blog/how-to-transcribe-your-audio-to-text.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-src-blog-how-to-transcribe-your-audio-to-text-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-src-blog-how-to-translate-zoom-recordings-mdx": () => import("./../../../src/templates/blog/post.js?__contentFilePath=/app/reduct-dot-video/src/blog/how-to-translate-zoom-recordings.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-src-blog-how-to-translate-zoom-recordings-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-src-blog-human-transcription-mdx": () => import("./../../../src/templates/blog/post.js?__contentFilePath=/app/reduct-dot-video/src/blog/human-transcription.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-src-blog-human-transcription-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-src-blog-human-transcription-services-mdx": () => import("./../../../src/templates/blog/post.js?__contentFilePath=/app/reduct-dot-video/src/blog/human-transcription-services.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-src-blog-human-transcription-services-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-src-blog-imovie-alternatives-mdx": () => import("./../../../src/templates/blog/post.js?__contentFilePath=/app/reduct-dot-video/src/blog/imovie-alternatives.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-src-blog-imovie-alternatives-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-src-blog-improving-recording-quality-in-zoom-tips-and-tricks-mdx": () => import("./../../../src/templates/blog/post.js?__contentFilePath=/app/reduct-dot-video/src/blog/improving-recording-quality-in-zoom-tips-and-tricks.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-src-blog-improving-recording-quality-in-zoom-tips-and-tricks-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-src-blog-industries-benefiting-from-text-based-video-editing-mdx": () => import("./../../../src/templates/blog/post.js?__contentFilePath=/app/reduct-dot-video/src/blog/industries-benefiting-from-text-based-video-editing.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-src-blog-industries-benefiting-from-text-based-video-editing-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-src-blog-innovating-research-mdx": () => import("./../../../src/templates/blog/post.js?__contentFilePath=/app/reduct-dot-video/src/blog/innovating-research.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-src-blog-innovating-research-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-src-blog-instant-interview-insights-mdx": () => import("./../../../src/templates/blog/post.js?__contentFilePath=/app/reduct-dot-video/src/blog/instant-interview-insights.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-src-blog-instant-interview-insights-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-src-blog-intuit-breaking-silos-ar-2022-mdx": () => import("./../../../src/templates/blog/post.js?__contentFilePath=/app/reduct-dot-video/src/blog/intuit-breaking-silos-AR2022.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-src-blog-intuit-breaking-silos-ar-2022-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-src-blog-intuit-tacos-and-taxes-mdx": () => import("./../../../src/templates/blog/post.js?__contentFilePath=/app/reduct-dot-video/src/blog/intuit-tacos-and-taxes.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-src-blog-intuit-tacos-and-taxes-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-src-blog-invideo-alternatives-mdx": () => import("./../../../src/templates/blog/post.js?__contentFilePath=/app/reduct-dot-video/src/blog/invideo-alternatives.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-src-blog-invideo-alternatives-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-src-blog-italian-transcription-software-mdx": () => import("./../../../src/templates/blog/post.js?__contentFilePath=/app/reduct-dot-video/src/blog/italian-transcription-software.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-src-blog-italian-transcription-software-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-src-blog-justicetext-alternatives-mdx": () => import("./../../../src/templates/blog/post.js?__contentFilePath=/app/reduct-dot-video/src/blog/justicetext-alternatives.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-src-blog-justicetext-alternatives-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-src-blog-law-enforcement-video-redaction-mdx": () => import("./../../../src/templates/blog/post.js?__contentFilePath=/app/reduct-dot-video/src/blog/law-enforcement-video-redaction.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-src-blog-law-enforcement-video-redaction-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-src-blog-legal-transcription-vs-dictation-mdx": () => import("./../../../src/templates/blog/post.js?__contentFilePath=/app/reduct-dot-video/src/blog/legal-transcription-vs-dictation.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-src-blog-legal-transcription-vs-dictation-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-src-blog-make-online-lectures-more-accessible-mdx": () => import("./../../../src/templates/blog/post.js?__contentFilePath=/app/reduct-dot-video/src/blog/make-online-lectures-more-accessible.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-src-blog-make-online-lectures-more-accessible-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-src-blog-make-your-videos-searchable-mdx": () => import("./../../../src/templates/blog/post.js?__contentFilePath=/app/reduct-dot-video/src/blog/make-your-videos-searchable.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-src-blog-make-your-videos-searchable-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-src-blog-manage-bwc-recording-mdx": () => import("./../../../src/templates/blog/post.js?__contentFilePath=/app/reduct-dot-video/src/blog/manage-bwc-recording.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-src-blog-manage-bwc-recording-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-src-blog-medical-transcription-mdx": () => import("./../../../src/templates/blog/post.js?__contentFilePath=/app/reduct-dot-video/src/blog/medical-transcription.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-src-blog-medical-transcription-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-src-blog-meeting-transcription-mdx": () => import("./../../../src/templates/blog/post.js?__contentFilePath=/app/reduct-dot-video/src/blog/meeting-transcription.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-src-blog-meeting-transcription-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-src-blog-multi-user-invite-mdx": () => import("./../../../src/templates/blog/post.js?__contentFilePath=/app/reduct-dot-video/src/blog/multi-user-invite.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-src-blog-multi-user-invite-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-src-blog-nacdl-race-matters-2024-mdx": () => import("./../../../src/templates/blog/post.js?__contentFilePath=/app/reduct-dot-video/src/blog/nacdl-race-matters-2024.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-src-blog-nacdl-race-matters-2024-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-src-blog-napd-changemakers-mdx": () => import("./../../../src/templates/blog/post.js?__contentFilePath=/app/reduct-dot-video/src/blog/napd-changemakers .mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-src-blog-napd-changemakers-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-src-blog-napd-reduct-aurora-mdx": () => import("./../../../src/templates/blog/post.js?__contentFilePath=/app/reduct-dot-video/src/blog/napd-reduct-aurora.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-src-blog-napd-reduct-aurora-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-src-blog-napd-seeing-is-believing-mdx": () => import("./../../../src/templates/blog/post.js?__contentFilePath=/app/reduct-dot-video/src/blog/napd-seeing-is-believing.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-src-blog-napd-seeing-is-believing-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-src-blog-navigating-new-normal-ignite-360-mdx": () => import("./../../../src/templates/blog/post.js?__contentFilePath=/app/reduct-dot-video/src/blog/navigating-new-normal-ignite360.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-src-blog-navigating-new-normal-ignite-360-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-src-blog-online-video-editor-mdx": () => import("./../../../src/templates/blog/post.js?__contentFilePath=/app/reduct-dot-video/src/blog/online-video-editor.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-src-blog-online-video-editor-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-src-blog-online-video-transcription-mdx": () => import("./../../../src/templates/blog/post.js?__contentFilePath=/app/reduct-dot-video/src/blog/online-video-transcription.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-src-blog-online-video-transcription-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-src-blog-optimize-remote-onboarding-videos-mdx": () => import("./../../../src/templates/blog/post.js?__contentFilePath=/app/reduct-dot-video/src/blog/optimize-remote-onboarding-videos.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-src-blog-optimize-remote-onboarding-videos-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-src-blog-otter-alternatives-mdx": () => import("./../../../src/templates/blog/post.js?__contentFilePath=/app/reduct-dot-video/src/blog/otter-alternatives.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-src-blog-otter-alternatives-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-src-blog-paper-edits-for-documentary-filmmakers-mdx": () => import("./../../../src/templates/blog/post.js?__contentFilePath=/app/reduct-dot-video/src/blog/paper-edits-for-documentary-filmmakers.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-src-blog-paper-edits-for-documentary-filmmakers-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-src-blog-pixelate-faces-in-videos-mdx": () => import("./../../../src/templates/blog/post.js?__contentFilePath=/app/reduct-dot-video/src/blog/pixelate-faces-in-videos.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-src-blog-pixelate-faces-in-videos-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-src-blog-portuguese-transcription-software-mdx": () => import("./../../../src/templates/blog/post.js?__contentFilePath=/app/reduct-dot-video/src/blog/portuguese-transcription-software.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-src-blog-portuguese-transcription-software-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-src-blog-premiere-pro-alternatives-mdx": () => import("./../../../src/templates/blog/post.js?__contentFilePath=/app/reduct-dot-video/src/blog/premiere-pro-alternatives.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-src-blog-premiere-pro-alternatives-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-src-blog-product-designers-should-learn-research-mdx": () => import("./../../../src/templates/blog/post.js?__contentFilePath=/app/reduct-dot-video/src/blog/product-designers-should-learn-research.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-src-blog-product-designers-should-learn-research-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-src-blog-professional-video-transcription-mdx": () => import("./../../../src/templates/blog/post.js?__contentFilePath=/app/reduct-dot-video/src/blog/professional-video-transcription.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-src-blog-professional-video-transcription-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-src-blog-protect-pii-in-body-cam-footage-mdx": () => import("./../../../src/templates/blog/post.js?__contentFilePath=/app/reduct-dot-video/src/blog/protect-pii-in-body-cam-footage.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-src-blog-protect-pii-in-body-cam-footage-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-src-blog-protect-pii-in-court-evidence-mdx": () => import("./../../../src/templates/blog/post.js?__contentFilePath=/app/reduct-dot-video/src/blog/protect-pii-in-court-evidence.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-src-blog-protect-pii-in-court-evidence-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-src-blog-qrca-2021-community-advice-mdx": () => import("./../../../src/templates/blog/post.js?__contentFilePath=/app/reduct-dot-video/src/blog/qrca-2021-community-advice.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-src-blog-qrca-2021-community-advice-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-src-blog-reasons-to-blur-video-mdx": () => import("./../../../src/templates/blog/post.js?__contentFilePath=/app/reduct-dot-video/src/blog/reasons-to-blur-video.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-src-blog-reasons-to-blur-video-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-src-blog-redact-video-mdx": () => import("./../../../src/templates/blog/post.js?__contentFilePath=/app/reduct-dot-video/src/blog/redact-video.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-src-blog-redact-video-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-src-blog-reduct-for-remote-corporate-training-mdx": () => import("./../../../src/templates/blog/post.js?__contentFilePath=/app/reduct-dot-video/src/blog/reduct-for-remote-corporate-training.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-src-blog-reduct-for-remote-corporate-training-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-src-blog-reduct-unlocks-video-for-business-mdx": () => import("./../../../src/templates/blog/post.js?__contentFilePath=/app/reduct-dot-video/src/blog/reduct-unlocks-video-for-business.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-src-blog-reduct-unlocks-video-for-business-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-src-blog-reinventing-meeting-minutes-mdx": () => import("./../../../src/templates/blog/post.js?__contentFilePath=/app/reduct-dot-video/src/blog/reinventing-meeting-minutes.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-src-blog-reinventing-meeting-minutes-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-src-blog-remote-research-revolution-mdx": () => import("./../../../src/templates/blog/post.js?__contentFilePath=/app/reduct-dot-video/src/blog/remote-research-revolution.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-src-blog-remote-research-revolution-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-src-blog-research-democratization-frictions-mdx": () => import("./../../../src/templates/blog/post.js?__contentFilePath=/app/reduct-dot-video/src/blog/research-democratization-frictions.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-src-blog-research-democratization-frictions-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-src-blog-research-snacks-mdx": () => import("./../../../src/templates/blog/post.js?__contentFilePath=/app/reduct-dot-video/src/blog/research-snacks.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-src-blog-research-snacks-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-src-blog-rev-alternatives-mdx": () => import("./../../../src/templates/blog/post.js?__contentFilePath=/app/reduct-dot-video/src/blog/rev-alternatives.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-src-blog-rev-alternatives-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-src-blog-review-body-cam-footage-mdx": () => import("./../../../src/templates/blog/post.js?__contentFilePath=/app/reduct-dot-video/src/blog/review-body-cam-footage.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-src-blog-review-body-cam-footage-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-src-blog-review-dui-video-footage-mdx": () => import("./../../../src/templates/blog/post.js?__contentFilePath=/app/reduct-dot-video/src/blog/review-dui-video-footage.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-src-blog-review-dui-video-footage-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-src-blog-rough-cut-video-editing-mdx": () => import("./../../../src/templates/blog/post.js?__contentFilePath=/app/reduct-dot-video/src/blog/rough-cut-video-editing.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-src-blog-rough-cut-video-editing-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-src-blog-search-for-spoken-words-in-videos-mdx": () => import("./../../../src/templates/blog/post.js?__contentFilePath=/app/reduct-dot-video/src/blog/search-for-spoken-words-in-videos.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-src-blog-search-for-spoken-words-in-videos-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-src-blog-searchable-repository-for-digital-evidence-mdx": () => import("./../../../src/templates/blog/post.js?__contentFilePath=/app/reduct-dot-video/src/blog/searchable-repository-for-digital-evidence.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-src-blog-searchable-repository-for-digital-evidence-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-src-blog-secure-blur-mdx": () => import("./../../../src/templates/blog/post.js?__contentFilePath=/app/reduct-dot-video/src/blog/secure-blur.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-src-blog-secure-blur-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-src-blog-secure-blur-use-cases-mdx": () => import("./../../../src/templates/blog/post.js?__contentFilePath=/app/reduct-dot-video/src/blog/secure-blur-use-cases.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-src-blog-secure-blur-use-cases-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-src-blog-security-in-captioning-software-mdx": () => import("./../../../src/templates/blog/post.js?__contentFilePath=/app/reduct-dot-video/src/blog/security-in-captioning-software.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-src-blog-security-in-captioning-software-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-src-blog-share-customer-feedback-at-scale-mdx": () => import("./../../../src/templates/blog/post.js?__contentFilePath=/app/reduct-dot-video/src/blog/share-customer-feedback-at-scale.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-src-blog-share-customer-feedback-at-scale-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-src-blog-slow-down-research-to-speed-up-team-mdx": () => import("./../../../src/templates/blog/post.js?__contentFilePath=/app/reduct-dot-video/src/blog/slow-down-research-to-speed-up-team.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-src-blog-slow-down-research-to-speed-up-team-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-src-blog-software-de-transcricao-mdx": () => import("./../../../src/templates/blog/post.js?__contentFilePath=/app/reduct-dot-video/src/blog/software-de-transcricao.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-src-blog-software-de-transcricao-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-src-blog-spanish-transcription-software-mdx": () => import("./../../../src/templates/blog/post.js?__contentFilePath=/app/reduct-dot-video/src/blog/spanish-transcription-software.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-src-blog-spanish-transcription-software-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-src-blog-sticky-insights-mdx": () => import("./../../../src/templates/blog/post.js?__contentFilePath=/app/reduct-dot-video/src/blog/sticky-insights.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-src-blog-sticky-insights-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-src-blog-stories-of-qual-research-featuring-phil-gordon-mdx": () => import("./../../../src/templates/blog/post.js?__contentFilePath=/app/reduct-dot-video/src/blog/stories-of-qual-research-featuring-phil-gordon.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-src-blog-stories-of-qual-research-featuring-phil-gordon-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-src-blog-subtitles-vs-closed-captions-mdx": () => import("./../../../src/templates/blog/post.js?__contentFilePath=/app/reduct-dot-video/src/blog/subtitles-vs-closed-captions.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-src-blog-subtitles-vs-closed-captions-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-src-blog-summarize-video-conferences-using-reduct-mdx": () => import("./../../../src/templates/blog/post.js?__contentFilePath=/app/reduct-dot-video/src/blog/summarize-video-conferences-using-reduct.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-src-blog-summarize-video-conferences-using-reduct-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-src-blog-summarizer-for-marketing-mdx": () => import("./../../../src/templates/blog/post.js?__contentFilePath=/app/reduct-dot-video/src/blog/summarizer-for-marketing.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-src-blog-summarizer-for-marketing-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-src-blog-tags-tagging-and-taxonomies-mdx": () => import("./../../../src/templates/blog/post.js?__contentFilePath=/app/reduct-dot-video/src/blog/tags-tagging-and-taxonomies.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-src-blog-tags-tagging-and-taxonomies-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-src-blog-take-effective-meeting-minutes-mdx": () => import("./../../../src/templates/blog/post.js?__contentFilePath=/app/reduct-dot-video/src/blog/take-effective-meeting-minutes.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-src-blog-take-effective-meeting-minutes-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-src-blog-takeaway-mdx": () => import("./../../../src/templates/blog/post.js?__contentFilePath=/app/reduct-dot-video/src/blog/takeaway.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-src-blog-takeaway-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-src-blog-taxonomy-fragments-to-inspire-your-tagging-structure-mdx": () => import("./../../../src/templates/blog/post.js?__contentFilePath=/app/reduct-dot-video/src/blog/taxonomy-fragments-to-inspire-your-tagging-structure.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-src-blog-taxonomy-fragments-to-inspire-your-tagging-structure-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-src-blog-temi-alternatives-mdx": () => import("./../../../src/templates/blog/post.js?__contentFilePath=/app/reduct-dot-video/src/blog/temi-alternatives.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-src-blog-temi-alternatives-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-src-blog-text-based-video-editing-benefits-mdx": () => import("./../../../src/templates/blog/post.js?__contentFilePath=/app/reduct-dot-video/src/blog/text-based-video-editing-benefits.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-src-blog-text-based-video-editing-benefits-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-src-blog-text-based-video-editing-vs-editing-text-in-video-mdx": () => import("./../../../src/templates/blog/post.js?__contentFilePath=/app/reduct-dot-video/src/blog/text-based-video-editing-vs-editing-text-in-video.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-src-blog-text-based-video-editing-vs-editing-text-in-video-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-src-blog-the-benefits-of-automated-transcription-for-deposition-mdx": () => import("./../../../src/templates/blog/post.js?__contentFilePath=/app/reduct-dot-video/src/blog/the-benefits-of-automated-transcription-for-deposition.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-src-blog-the-benefits-of-automated-transcription-for-deposition-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-src-blog-the-power-of-using-video-in-court-mdx": () => import("./../../../src/templates/blog/post.js?__contentFilePath=/app/reduct-dot-video/src/blog/the-power-of-using-video-in-court.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-src-blog-the-power-of-using-video-in-court-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-src-blog-the-ultimate-guide-to-repurposing-your-video-content-mdx": () => import("./../../../src/templates/blog/post.js?__contentFilePath=/app/reduct-dot-video/src/blog/the-ultimate-guide-to-repurposing-your-video-content.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-src-blog-the-ultimate-guide-to-repurposing-your-video-content-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-src-blog-things-to-avoid-when-redacting-documents-mdx": () => import("./../../../src/templates/blog/post.js?__contentFilePath=/app/reduct-dot-video/src/blog/things-to-avoid-when-redacting-documents.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-src-blog-things-to-avoid-when-redacting-documents-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-src-blog-timeline-vs-text-based-mdx": () => import("./../../../src/templates/blog/post.js?__contentFilePath=/app/reduct-dot-video/src/blog/timeline-vs-text-based.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-src-blog-timeline-vs-text-based-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-src-blog-title-slides-mdx": () => import("./../../../src/templates/blog/post.js?__contentFilePath=/app/reduct-dot-video/src/blog/title-slides.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-src-blog-title-slides-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-src-blog-tldv-alternatives-mdx": () => import("./../../../src/templates/blog/post.js?__contentFilePath=/app/reduct-dot-video/src/blog/tldv-alternatives.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-src-blog-tldv-alternatives-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-src-blog-top-5-sonix-ai-alternatives-mdx": () => import("./../../../src/templates/blog/post.js?__contentFilePath=/app/reduct-dot-video/src/blog/top-5-sonix-ai-alternatives.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-src-blog-top-5-sonix-ai-alternatives-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-src-blog-top-6-captioning-softwares-mdx": () => import("./../../../src/templates/blog/post.js?__contentFilePath=/app/reduct-dot-video/src/blog/top-6-captioning-softwares.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-src-blog-top-6-captioning-softwares-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-src-blog-top-transkriptionssoftware-mdx": () => import("./../../../src/templates/blog/post.js?__contentFilePath=/app/reduct-dot-video/src/blog/top-transkriptionssoftware.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-src-blog-top-transkriptionssoftware-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-src-blog-train-non-researchers-to-do-research-mdx": () => import("./../../../src/templates/blog/post.js?__contentFilePath=/app/reduct-dot-video/src/blog/train-non-researchers-to-do-research.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-src-blog-train-non-researchers-to-do-research-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-src-blog-transcribe-911-calls-mdx": () => import("./../../../src/templates/blog/post.js?__contentFilePath=/app/reduct-dot-video/src/blog/transcribe-911-calls.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-src-blog-transcribe-911-calls-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-src-blog-transcribe-body-cam-mdx": () => import("./../../../src/templates/blog/post.js?__contentFilePath=/app/reduct-dot-video/src/blog/transcribe-body-cam.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-src-blog-transcribe-body-cam-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-src-blog-transcribe-deposition-mdx": () => import("./../../../src/templates/blog/post.js?__contentFilePath=/app/reduct-dot-video/src/blog/transcribe-deposition.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-src-blog-transcribe-deposition-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-src-blog-transcribe-interrogation-videos-mdx": () => import("./../../../src/templates/blog/post.js?__contentFilePath=/app/reduct-dot-video/src/blog/transcribe-interrogation-videos.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-src-blog-transcribe-interrogation-videos-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-src-blog-transcribe-lecture-videos-mdx": () => import("./../../../src/templates/blog/post.js?__contentFilePath=/app/reduct-dot-video/src/blog/transcribe-lecture-videos.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-src-blog-transcribe-lecture-videos-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-src-blog-transcribe-video-to-text-mdx": () => import("./../../../src/templates/blog/post.js?__contentFilePath=/app/reduct-dot-video/src/blog/transcribe-video-to-text.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-src-blog-transcribe-video-to-text-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-src-blog-transcribe-witness-statements-mdx": () => import("./../../../src/templates/blog/post.js?__contentFilePath=/app/reduct-dot-video/src/blog/transcribe-witness-statements.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-src-blog-transcribe-witness-statements-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-src-blog-transcribe-your-podcasts-automatically-mdx": () => import("./../../../src/templates/blog/post.js?__contentFilePath=/app/reduct-dot-video/src/blog/transcribe-your-podcasts-automatically.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-src-blog-transcribe-your-podcasts-automatically-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-src-blog-transcribe-zoom-recordings-mdx": () => import("./../../../src/templates/blog/post.js?__contentFilePath=/app/reduct-dot-video/src/blog/transcribe-zoom-recordings.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-src-blog-transcribe-zoom-recordings-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-src-blog-transcribeme-alternatives-mdx": () => import("./../../../src/templates/blog/post.js?__contentFilePath=/app/reduct-dot-video/src/blog/transcribeme-alternatives.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-src-blog-transcribeme-alternatives-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-src-blog-transcription-bot-for-zoom-mdx": () => import("./../../../src/templates/blog/post.js?__contentFilePath=/app/reduct-dot-video/src/blog/transcription-bot-for-zoom.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-src-blog-transcription-bot-for-zoom-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-src-blog-transcription-practice-for-beginners-and-intermediate-transcriptionists-mdx": () => import("./../../../src/templates/blog/post.js?__contentFilePath=/app/reduct-dot-video/src/blog/transcription-practice-for-beginners-and-intermediate-transcriptionists.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-src-blog-transcription-practice-for-beginners-and-intermediate-transcriptionists-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-src-blog-transcription-software-for-video-mdx": () => import("./../../../src/templates/blog/post.js?__contentFilePath=/app/reduct-dot-video/src/blog/transcription-software-for-video.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-src-blog-transcription-software-for-video-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-src-blog-translation-video-audio-mdx": () => import("./../../../src/templates/blog/post.js?__contentFilePath=/app/reduct-dot-video/src/blog/translation-video-audio.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-src-blog-translation-video-audio-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-src-blog-trint-alternatives-mdx": () => import("./../../../src/templates/blog/post.js?__contentFilePath=/app/reduct-dot-video/src/blog/trint-alternatives.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-src-blog-trint-alternatives-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-src-blog-type-studio-alternatives-mdx": () => import("./../../../src/templates/blog/post.js?__contentFilePath=/app/reduct-dot-video/src/blog/type-studio-alternatives.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-src-blog-type-studio-alternatives-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-src-blog-utilize-reduct-for-audiovisual-evidence-mdx": () => import("./../../../src/templates/blog/post.js?__contentFilePath=/app/reduct-dot-video/src/blog/utilize-reduct-for-audiovisual-evidence.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-src-blog-utilize-reduct-for-audiovisual-evidence-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-src-blog-ux-research-transcription-mdx": () => import("./../../../src/templates/blog/post.js?__contentFilePath=/app/reduct-dot-video/src/blog/ux-research-transcription.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-src-blog-ux-research-transcription-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-src-blog-video-discovery-for-public-defense-mdx": () => import("./../../../src/templates/blog/post.js?__contentFilePath=/app/reduct-dot-video/src/blog/video-discovery-for-public-defense.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-src-blog-video-discovery-for-public-defense-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-src-blog-video-editing-as-easy-as-editing-text-mdx": () => import("./../../../src/templates/blog/post.js?__contentFilePath=/app/reduct-dot-video/src/blog/video-editing-as-easy-as-editing-text.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-src-blog-video-editing-as-easy-as-editing-text-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-src-blog-video-exhibits-for-public-defenders-mdx": () => import("./../../../src/templates/blog/post.js?__contentFilePath=/app/reduct-dot-video/src/blog/video-exhibits-for-public-defenders.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-src-blog-video-exhibits-for-public-defenders-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-src-blog-video-logging-software-mdx": () => import("./../../../src/templates/blog/post.js?__contentFilePath=/app/reduct-dot-video/src/blog/video-logging-software.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-src-blog-video-logging-software-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-src-blog-video-redaction-mdx": () => import("./../../../src/templates/blog/post.js?__contentFilePath=/app/reduct-dot-video/src/blog/video-redaction.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-src-blog-video-redaction-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-src-blog-video-shareout-mdx": () => import("./../../../src/templates/blog/post.js?__contentFilePath=/app/reduct-dot-video/src/blog/video-shareout.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-src-blog-video-shareout-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-src-blog-video-text-editing-mdx": () => import("./../../../src/templates/blog/post.js?__contentFilePath=/app/reduct-dot-video/src/blog/video-text-editing.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-src-blog-video-text-editing-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-src-blog-video-to-text-transcription-services-mdx": () => import("./../../../src/templates/blog/post.js?__contentFilePath=/app/reduct-dot-video/src/blog/video-to-text-transcription-services.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-src-blog-video-to-text-transcription-services-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-src-blog-videoboard-coming-soon-mdx": () => import("./../../../src/templates/blog/post.js?__contentFilePath=/app/reduct-dot-video/src/blog/videoboard-coming-soon.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-src-blog-videoboard-coming-soon-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-src-blog-videoboard-intro-ar-2022-mdx": () => import("./../../../src/templates/blog/post.js?__contentFilePath=/app/reduct-dot-video/src/blog/videoboard-intro-AR2022.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-src-blog-videoboard-intro-ar-2022-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-src-blog-videoboard-launch-mdx": () => import("./../../../src/templates/blog/post.js?__contentFilePath=/app/reduct-dot-video/src/blog/videoboard-launch.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-src-blog-videoboard-launch-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-src-blog-videoboard-thinking-mdx": () => import("./../../../src/templates/blog/post.js?__contentFilePath=/app/reduct-dot-video/src/blog/videoboard-thinking.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-src-blog-videoboard-thinking-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-src-blog-virtual-event-highlight-reels-mdx": () => import("./../../../src/templates/blog/post.js?__contentFilePath=/app/reduct-dot-video/src/blog/virtual-event-highlight-reels.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-src-blog-virtual-event-highlight-reels-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-src-blog-virtual-event-recordings-mdx": () => import("./../../../src/templates/blog/post.js?__contentFilePath=/app/reduct-dot-video/src/blog/virtual-event-recordings.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-src-blog-virtual-event-recordings-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-src-blog-ways-to-handle-bodycam-footage-faster-mdx": () => import("./../../../src/templates/blog/post.js?__contentFilePath=/app/reduct-dot-video/src/blog/ways-to-handle-bodycam-footage-faster.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-src-blog-ways-to-handle-bodycam-footage-faster-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-src-blog-ways-to-repurpose-lectures-mdx": () => import("./../../../src/templates/blog/post.js?__contentFilePath=/app/reduct-dot-video/src/blog/ways-to-repurpose-lectures.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-src-blog-ways-to-repurpose-lectures-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-src-blog-when-journey-mapping-met-storytelling-mdx": () => import("./../../../src/templates/blog/post.js?__contentFilePath=/app/reduct-dot-video/src/blog/when-journey-mapping-met-storytelling.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-src-blog-when-journey-mapping-met-storytelling-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-src-blog-why-redact-pii-from-legal-transcripts-mdx": () => import("./../../../src/templates/blog/post.js?__contentFilePath=/app/reduct-dot-video/src/blog/why-redact-pii-from-legal-transcripts.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-src-blog-why-redact-pii-from-legal-transcripts-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-src-blog-why-transcription-is-important-to-design-research-mdx": () => import("./../../../src/templates/blog/post.js?__contentFilePath=/app/reduct-dot-video/src/blog/why-transcription-is-important-to-design-research.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-src-blog-why-transcription-is-important-to-design-research-mdx" */),
  "component---src-templates-guidelines-post-js-content-file-path-src-guidelines-background-audio-and-sounds-mdx": () => import("./../../../src/templates/guidelines/post.js?__contentFilePath=/app/reduct-dot-video/src/guidelines/background-audio-and-sounds.mdx" /* webpackChunkName: "component---src-templates-guidelines-post-js-content-file-path-src-guidelines-background-audio-and-sounds-mdx" */),
  "component---src-templates-guidelines-post-js-content-file-path-src-guidelines-common-errors-mdx": () => import("./../../../src/templates/guidelines/post.js?__contentFilePath=/app/reduct-dot-video/src/guidelines/common-errors.mdx" /* webpackChunkName: "component---src-templates-guidelines-post-js-content-file-path-src-guidelines-common-errors-mdx" */),
  "component---src-templates-guidelines-post-js-content-file-path-src-guidelines-false-sentence-starts-mdx": () => import("./../../../src/templates/guidelines/post.js?__contentFilePath=/app/reduct-dot-video/src/guidelines/false-sentence-starts.mdx" /* webpackChunkName: "component---src-templates-guidelines-post-js-content-file-path-src-guidelines-false-sentence-starts-mdx" */),
  "component---src-templates-guidelines-post-js-content-file-path-src-guidelines-filler-words-and-phrases-mdx": () => import("./../../../src/templates/guidelines/post.js?__contentFilePath=/app/reduct-dot-video/src/guidelines/filler-words-and-phrases.mdx" /* webpackChunkName: "component---src-templates-guidelines-post-js-content-file-path-src-guidelines-filler-words-and-phrases-mdx" */),
  "component---src-templates-guidelines-post-js-content-file-path-src-guidelines-foreign-language-mdx": () => import("./../../../src/templates/guidelines/post.js?__contentFilePath=/app/reduct-dot-video/src/guidelines/foreign-language.mdx" /* webpackChunkName: "component---src-templates-guidelines-post-js-content-file-path-src-guidelines-foreign-language-mdx" */),
  "component---src-templates-guidelines-post-js-content-file-path-src-guidelines-format-of-transcripts-mdx": () => import("./../../../src/templates/guidelines/post.js?__contentFilePath=/app/reduct-dot-video/src/guidelines/format-of-transcripts.mdx" /* webpackChunkName: "component---src-templates-guidelines-post-js-content-file-path-src-guidelines-format-of-transcripts-mdx" */),
  "component---src-templates-guidelines-post-js-content-file-path-src-guidelines-grammatical-rules-mdx": () => import("./../../../src/templates/guidelines/post.js?__contentFilePath=/app/reduct-dot-video/src/guidelines/grammatical-rules.mdx" /* webpackChunkName: "component---src-templates-guidelines-post-js-content-file-path-src-guidelines-grammatical-rules-mdx" */),
  "component---src-templates-guidelines-post-js-content-file-path-src-guidelines-inaudible-material-and-crosstalk-mdx": () => import("./../../../src/templates/guidelines/post.js?__contentFilePath=/app/reduct-dot-video/src/guidelines/inaudible-material-and-crosstalk.mdx" /* webpackChunkName: "component---src-templates-guidelines-post-js-content-file-path-src-guidelines-inaudible-material-and-crosstalk-mdx" */),
  "component---src-templates-guidelines-post-js-content-file-path-src-guidelines-numbers-mdx": () => import("./../../../src/templates/guidelines/post.js?__contentFilePath=/app/reduct-dot-video/src/guidelines/numbers.mdx" /* webpackChunkName: "component---src-templates-guidelines-post-js-content-file-path-src-guidelines-numbers-mdx" */),
  "component---src-templates-guidelines-post-js-content-file-path-src-guidelines-quotations-and-single-inverted-commas-mdx": () => import("./../../../src/templates/guidelines/post.js?__contentFilePath=/app/reduct-dot-video/src/guidelines/quotations-and-single-inverted-commas.mdx" /* webpackChunkName: "component---src-templates-guidelines-post-js-content-file-path-src-guidelines-quotations-and-single-inverted-commas-mdx" */),
  "component---src-templates-guidelines-post-js-content-file-path-src-guidelines-stutters-and-repetitions-mdx": () => import("./../../../src/templates/guidelines/post.js?__contentFilePath=/app/reduct-dot-video/src/guidelines/stutters-and-repetitions.mdx" /* webpackChunkName: "component---src-templates-guidelines-post-js-content-file-path-src-guidelines-stutters-and-repetitions-mdx" */)
}

